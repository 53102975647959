<template>
  <section>
    <loading-retry
      v-if="previousYearProjects === null"
      :fetchPromise="init"
      retryMessage="ไม่สามารถแสดงข้อมูลโครงการปีก่อนหน้า"
    ></loading-retry>
    <div v-if="previousYearProjects !== null">
      <div class="row mt-4">
        <div
          class="
            col-md-8
            offset-md-2
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <h4>ความคืบหน้าโครงการปีก่อนหน้า</h4>
          <div class="px-4 d-flex flex-row justify-content-center">
            <router-link
              :to="{ name: 'peno.announcement' }"
              class="btn btn-outline-red px-2 mr-3"
            >
              <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
              กลับ
            </router-link>
            <router-link
              :to="{ name: 'peno.plan_relation' }"
              class="btn btn-action"
            >
              เริ่มจัดทำแผน
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="row mt-4"
        v-for="(project, index) in previousYearProjects"
        v-bind:key="project.id"
      >
        <div class="col-md-2 text-md-right">
          <h5 class="text-gray">โครงการที่ {{ parseInt(index) + 1 }}</h5>
        </div>
        <div class="col-md-8 box">
          <div class="box-body">
            <div class="row border-bottom">
              <div
                class="
                  col
                  d-flex
                  align-items-start
                  justify-content-between
                  flex-nowrap
                "
              >
                <p class="text-h5">{{ project.name }}</p>
                <p>ปี {{ project.year | thai_year }}</p>
              </div>
            </div>
            <div class="row mt-2 pb-2 d-flex justify-content-center">
              <div class="col-4 col-md-3"></div>
              <div class="col-4 col-md-4 text-right">
                <span class="font-weight-bold">งบ ฯ ที่เสนอขอ</span> (บาท)
              </div>
              <div class="col-4 col-md-4 text-right">
                <span class="font-weight-bold">งบ ฯ ที่ได้รับ</span> (บาท)
              </div>
            </div>
            <div
              class="row mt-2 pb-2 d-flex justify-content-center"
              v-for="budget in project.project_budgets"
              :key="budget.year"
            >
              <div class="col-4 col-md-3 font-weight-bold">
                ปี {{ budget.year }}
              </div>
              <div class="col-4 col-md-4 text-right">
                <div>{{ budget.budget_expected | auto_number }}</div>
              </div>
              <div class="col-4 col-md-4 text-right">
                <div>{{ budget.budget_actual | auto_number }}</div>
              </div>
            </div>
            <div
              v-if="!project.project_budgets || !project.project_budgets.length"
              class="row mt-2 pb-2 d-flex justify-content-center"
            >
              <div class="col-4 col-md-3 font-weight-bold">-</div>
              <div class="col-4 col-md-4 text-right">
                <div>-</div>
              </div>
              <div class="col-4 col-md-4 text-right">
                <div>-</div>
              </div>
            </div>
            <div
              class="row d-flex justify-content-center border-bottom pb-3 mb-3"
            >
              <div class="font-weight-bold"><u>แหล่งงบ</u> &nbsp;&nbsp;</div>
              <div>
                <ul>
                  <li
                    v-for="(source, sourceIndex) in project.budget_sources"
                    :key="sourceIndex"
                  >
                    <span
                      v-for="listSource in budgetSources"
                      :key="listSource.id"
                    >
                      <span v-if="listSource.id == source.type">
                        {{ listSource.title }}
                        <span v-if="source.type == 6">
                          ({{ source.other }})
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2 pb-2 border-bottom">
              <div class="col-md-6 plan-target-result">
                <div class="font-weight-bold text-gray">
                  เป้าหมายผลลัพธ์สำคัญตลอดโครงการ (1 ปี)
                </div>
                <ul>
                  <li
                    v-for="targetResult in project['target-results'].data"
                    :key="targetResult.id"
                  >
                    <div>{{ targetResultDisplay(targetResult, false) }}</div>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div class="font-weight-bold">
                  ผลลัพธ์จริงในปี {{ project.year | thai_year }}
                </div>
                <ul>
                  <li
                    v-for="targetResult in project['target-results'].data"
                    :key="targetResult.id"
                  >
                    <div>{{ targetResultDisplay(targetResult, true) }}</div>
                  </li>
                  <li v-if="project.actual_target_result">
                    อื่นๆ - {{ project.actual_target_result }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2 pb-2 border-bottom">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4">
                    <div class="text-gray">ตัวชี้วัด</div>
                  </div>
                  <div class="col-4">
                    <div class="text-gray">ผลผลิตที่คาดหวัง</div>
                  </div>
                  <div class="col-4">
                    <div>ผลผลิตจริง</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div>
                      {{ indicator(project) }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      v-for="targetIndicator in project.project_target_indicators"
                      :key="targetIndicator.id"
                    >
                      {{
                        targetIndicator.indicator_value_expected | auto_number(false, 3)
                      }}
                      <span
                        v-show="targetIndicator.indicator_unit_expected != null"
                      >
                        ({{ targetIndicator.indicator_unit_expected }})
                      </span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      v-for="targetIndicator in project.project_target_indicators"
                      :key="targetIndicator.id"
                    >
                      {{
                        targetIndicator.indicator_value_result | auto_number(false, 3)
                      }}
                      <span
                        v-if="targetIndicator.indicator_unit_expected != null"
                      >
                        ({{ targetIndicator.indicator_unit_expected }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="font-weight-bold">อุปสรรคสำคัญ</div>
                {{ project.threat || '-' }}
              </div>
            </div>
            <div class="row mt-2 pb-2 d-flex justify-content-end">
              <button
                class="btn btn-outline-red py-0 mt-2"
                @click="showProjectEditor(project)"
              >
                อัปเดตผลลัพธ์
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container @onUpdated="updatePreviousYearProject"></modals-container>
  </section>
</template>

<script>
import LoadingRetry from '@/components/LoadingRetry.vue';
import ProjectEditor from '@/components/ProjectEditor.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {get} from 'lodash';

export default {
  name: 'previous-year-project',
  data() {
    return {
      error: false,
      ready: false,
      editProject: {},
    };
  },
  components: {
    LoadingRetry,
  },
  computed: {
    ...mapState('peno', ['previousYearProjects', 'budgetSources']),
    ...mapGetters('peno', ['currentProvince']),
  },
  methods: {
    ...mapActions('peno', ['fetchPreviousYearProjects']),
    ...mapMutations('peno', ['updatePreviousYearProject']),
    init() {
      return this.fetchPreviousYearProjects({
        province: this.currentProvince,
      });
    },
    indicator(project) {
      switch (project['target-results'].data[0].target.data.type) {
        case 1:
          return 'RE';
        case 2:
          return 'EE';
        case 3:
          return project['target-results'].data[0].target.data.type_other;
        default:
          return '-';
      }
    },
    indicatorExpected(project) {
      return project['target-results'].data[0].target.data.indicator_value;
    },
    targetResultDisplay(targetResult, isResult) {
      return (
        targetResult.category_title +
        ' - ' +
        targetResult.name +
        ' ' +
        this.$options.filters.auto_number(
          isResult ? targetResult.result_value : targetResult.expected_value
        ) +
        ' ' +
        targetResult.unit
      );
    },
    showProjectEditor(project) {
      this.$modal.show(
        ProjectEditor,
        {
          name: 'project_editor',
          mode: 'edit_previous',
          project: project,
          target: project.target,
        },
        {
          name: 'project_editor',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .budget-icon {
    display: none;
  }
}

.budget-icon {
  border: 1px solid grey;
  border-radius: 100%;
  padding: 7px;
  fill: gray;
}

.plan-target-result {
  border-right: 1px solid #dee2e6;
}

@media (max-width: 767px) {
  .plan-target-result {
    border-right: none;
  }
}
</style>
